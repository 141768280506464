<template>
  <div class="">
    <v-data-table
      :headers="users_headers"
      :items="users"
      item-key="login"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
      :loading="loading_users"
      loading-text="Загрузка... Подождите"
    >
      <template v-slot:top>
        <v-toolbar dense flat>
          <v-text-field
            v-model="search"
            hide-details
            label="Search"
            prepend-icon="mdi-magnify"
            single-line
          ></v-text-field>

          <v-btn icon @click="add_item">
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-dialog v-model="dialog" max-width="500px" @click:outside="close">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid">
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Имя*"
                      hint="Имя пользователя"
                      :rules="requireRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.surname"
                      label="Фамилия*"
                      hint="Фамилия пользователя"
                      :rules="requireRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.company_position"
                      label="Должность*"
                      :rules="requireRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.login"
                      :rules="emailRules"
                      label="Email*"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.password"
                      type="password"
                      label="Пароль*"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-autocomplete
                      label="Роль"
                      v-model="editedItem.role.name"
                      :items="roles"
                      :rules="requireRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="editedItem.active"
                      label="Активен"
                    ></v-checkbox>
                  </v-col>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="close"> Отмена </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="editUser(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Редактировать</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "List_users",
  data() {
    return {
      search: "",
      calories: "",
      dialog: false,
      editedIndex: -1,
      valid: true,
      editedItem: {
        name: "",
        login: "",
        surname: "",
        company_position: "",
        password: "",
        role: {
          name: ""
        },
        active: false,
      },
      defaultItem: {
        name: null,
        login: null,
        surname: null,
        company_position: null,
        password: null,
        role: {
          name: null
        },
        active: false,
      },
      nameRules: [(v) => !!v || "Имя обязательно для заполнения"],
      emailRules: [
        (v) => !!v || "E-mail обязателен",
        (v) => /.+@.+\..+/.test(v) || "Некорректный E-mail",
      ],
      requireRules: [(v) => !!v || "Обязательно для заполнения"],
    };
  },
  created() {
    this.$store.dispatch("list_users");
  },
  computed: {
    loading_users() {
      return this.$store.getters.LOADING_USERS;
    },
    roles() {
      return ["superadmin", "support", "manager"];
    },
    users_headers() {
      return [
        {
          text: "Имя",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Фамилия",
          align: "start",
          sortable: true,
          value: "surname",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "login",
        },
        {
          text: "Должность",
          align: "start",
          sortable: true,
          value: "company_position",
        },
        {
          text: "Роль",
          align: "start",
          sortable: true,
          value: "role.name",
        },
        {
          text: "Активен",
          align: "start",
          sortable: true,
          value: "active",
        },
        {
          text: "Last login",
          align: "start",
          sortable: true,
          value: "last_login_at",
        },
        { text: "", value: "actions", sortable: false },
      ];
    },
    users() {
      return this.$store.getters.USERS;
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Добавить пользователя"
        : "Изменить пользователя";
    },
  },
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search) !== -1
      );
    },
    add_item() {
      this.editUser(this.defaultItem);
    },
    editUser(item) {
      this.dialog = true;
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.form.resetValidation()
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    save() {
      this.validate();

      if (!this.valid) return;

      let user = {
        name: this.editedItem.name,
        surname: this.editedItem.surname,
        login: this.editedItem.login,
        password: this.editedItem.password,
        position: this.editedItem.company_position,
        active: this.editedItem.active,
        role: this.editedItem.role.name,
      };

      if (this.editedIndex > -1) {
        user.user_id = this.editedItem.id
        this.$store.dispatch("update_user", user).then(() => {
          this.$store.dispatch("list_users", this.params);
        });
      } else {
        this.$store.dispatch("create_user", user).then(() => {
          this.$store.dispatch("list_users", this.params);
        });
      }
      this.close();
    },
  },
};
</script>

<style scoped></style>
